import axios from "axios"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { useState } from "react"
import { Card } from "react-bootstrap"
import { CSVLink } from "react-csv"
import * as Feather from 'react-feather'
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import Option from "../components/Option"
import CollapseCard from "../components/custom/CollapseCard"
import FormInput from "../components/custom/FormInput"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import { YearOptions } from "../config/functions"
import { CourseType } from "../redux/actions/courseActions"
import { DegreeType } from "../redux/actions/degreeAction"
import { DisciplineType } from "../redux/actions/disciplineAction"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { DropDownType } from "../redux/actions/dropDownAction"
import { ExamType } from "../redux/actions/examAction"
import { StoreState } from "../redux/reducers"
import imgPath from './img/collegeLogo.png'

interface MarkAnalysisType {
    name: string,
    registerNo: string,
    departmentRegNo: string,
    intMark: number
    extMark: number
    intConvertedMark: number
    extConvertedMark: number
    totConvertedMark: number
    adjustedMark: number
    grade: number
    gradeText: string
    unvGrade: number
    unvGradeText: string
}

const MarkReport = () => {

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')
    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')
    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')
    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')
    const [batch, setBatch] = useState<string>('')
    const [batchError, setBatchError] = useState<string>('')
    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')
    const yearList = YearOptions();
    const [courseType, setCourseType] = useState<string>('');

    const [markReport, setMarkReport] = useState<MarkAnalysisType[]>([])

    const handleSearch = () => {
        if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && semester !== '' && disciplineCourse !== '') {

            // let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse && f.courseType === 'Theory')?.id

            let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse && f.courseType === courseType)?.id

            axios.get<{ data: MarkAnalysisType[] }>(`${apiUrl}/studentMark/markAnalysis/?examId=${exam}&degreeId=${degree}&disciplineId=${discipline}&batch=${batch}&semester=${semester}&disciplineCourseId=${disciplineCourseId}`)
                .then((response) => {
                    const result = response.data.data
                    if (result.length > 0) {
                        setMarkReport(result)
                    } else {
                        setMarkReport([])
                    }
                })
                .catch((error) => {
                    toast.error(error);
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

            setMarkReport([])
        }
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        let examName = examList.find(f => f.id?.toString() === exam)?.title
        let degreeName = degreeList.find(d => d.id?.toString() === degree)?.name
        let disciplineName = disciplineList.find(d => d.id?.toString() === discipline)?.name
        let courseName = courseList.find(d => d.id?.toString() === disciplineCourse)?.name
        let courseCode = courseList.find(d => d.id?.toString() === disciplineCourse)?.shortName

        doc.addImage(imgPath, 'PNG', 20, 10, 170, 40);
        doc.setFontSize(13);
        doc.setFont('Arial', 'normal', 'bold')
        doc.text('Mark Report', 80, 50)
        doc.text(`${examName}`, 75, 57);

        doc.setFontSize(11);
        doc.text(`Degree & Discipline : ${degreeName} - ${disciplineName}`, 15, 67)
        doc.text(`Course : ${courseName}`, 15, 75)
        doc.text(`Course Code : ${courseCode}`, 155, 75)
        doc.text(`Batch : ${batch}`, 15, 83)
        doc.text(`Sem : ${semester}`, 155, 83)


        autoTable(doc, {
            startY: 88,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], halign: 'center' },
            head: [
                [
                    { content: "S.No.", styles: { halign: 'center' } },
                    { content: "Name", styles: { halign: 'center' } },
                    { content: "Register No", styles: { halign: 'center' } },
                    { content: "Department Reg.no", styles: { halign: 'center' } },
                    { content: "Internal Mark", styles: { halign: 'center' } },
                    { content: "External Mark", styles: { halign: 'center' } },
                    { content: "Int Converted Mark", styles: { halign: 'center' } },
                    { content: "Ext Converted Mark", styles: { halign: 'center' } },
                    { content: "Total Converted Mark", styles: { halign: 'center' } },
                    { content: "Adjusted Mark", styles: { halign: 'center' } },
                    { content: "Grade", styles: { halign: 'center' } },
                    { content: "UNV Grade", styles: { halign: 'center' } }
                ]

            ],
            body: markReport?.map((ma, i) => {
                return [(i + 1)?.toString(), ma.name, ma.registerNo, ma.departmentRegNo, ma.intMark ? ma.intMark : '-', ma.extMark ? ma.extMark : '-', ma.intConvertedMark ? ma.intConvertedMark : '-', ma.extConvertedMark ? ma.extConvertedMark : '-', ma.totConvertedMark ? ma.totConvertedMark : '-', ma.adjustedMark ? ma.adjustedMark : '-', ma.gradeText ? ma.gradeText : '-', ma.unvGradeText ? ma.unvGradeText : '-']
            }),
            theme: 'grid',
            tableLineColor: "black",
            margin: { left: 15 }
        })

        doc.save('mark_report .pdf');

    }

    const csvData = [
        ['S.No.', 'Name', 'Register No', 'Department Reg.no', 'Internal Mark', 'External Mark', 'Int Converted Mark', 'Ext Converted Mark', 'Total Converted Mark', 'Adjust Mark', 'Grade', 'UNV Grade'],
    ]
    markReport?.forEach((mr, i) => {
        csvData.push(
            [(i + 1)?.toString(), mr.name, mr.registerNo, mr.departmentRegNo, mr.intMark?.toString(), mr.extMark?.toString(), mr.intConvertedMark?.toString(), mr.extConvertedMark?.toString(), mr.totConvertedMark?.toString(), mr.adjustedMark?.toString(), mr.gradeText, mr.unvGradeText]
        )
    })

    return <>
        <PageContainer title='Mark Report'>
            <Card>
                <div className="row m-2">
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Exam'
                            name='Exam'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={exam}
                            onChange={(e) => {
                                setExam(e.target.value)
                                setExamError('')
                            }}
                            errorText={examError}
                        >
                            <Option value=''>Select</Option>
                            {examList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.title}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Degree'
                            name='Degree'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={degree}
                            onChange={(e) => {
                                setDegree(e.target.value)
                                setDegreeError('')
                            }}
                            errorText={degreeError}
                        >
                            <Option value=''>Select</Option>
                            {degreeList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Discipline'
                            name='Discipline'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={discipline}
                            onChange={(e) => {
                                setDiscipline(e.target.value)
                                setDisciplineError('')
                            }}
                            errorText={disciplineError}
                        >
                            <Option value=''>Select</Option>
                            {disciplineList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Semester"
                            label="semester"
                            labelClassName="required"
                            value={semester}
                            onChange={(e) => {
                                setSemester(e.target.value)
                                setSemesterError('')
                            }}
                            placeholder="Select Sem"
                            containerClass="mt-2"
                            type="select"
                            errorText={semesterError}
                        >
                            <option value="">Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </FormInput>

                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="mt-2 mb-2">Course Type : </label>
                        <select
                            className="form-control"
                            name="CourseHead"
                            onChange={(e) => {
                                setCourseType(e.target.value);
                            }}
                            value={courseType}
                        >
                            <option value="">Select Course Type</option>
                            {dropDownList?.filter(dl => dl.category === 'Course Type')?.map((dd) => {
                                return <option value={dd.title} key={dd.id}>{dd.title}</option>
                            })
                            }

                        </select>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Course'
                            name='Course'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={disciplineCourse}
                            onChange={(e) => {
                                setDisciplineCourse(e.target.value)
                                setDisciplineCourseError('')
                            }}
                            errorText={disciplineCourseError}
                        >
                            <Option value=''>Select</Option>
                            {/* {courseList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })} */}
                            {courseList?.map((course) => {
                                const filteredCourses = disciplineCourseList.filter(
                                    (dc) => dc.degreeId === parseInt(degree) && dc.disciplineId === parseInt(discipline) && dc.courseId === course.id && dc.courseType === courseType && dc.semesterNo === parseInt(semester)
                                );
                                // const filteredCourses = disciplineCourseList.filter(
                                //     (dc) => dc.degreeId === parseInt(degree) && dc.disciplineId === parseInt(discipline) && dc.courseId === course.id && (dc.courseType === 'Theory')
                                // );
                                return filteredCourses.map((filteredCourse) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ));
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Batch"
                            label="Batch"
                            labelClassName="required"
                            value={batch}
                            onChange={(e) => {
                                setBatch(e.target.value)
                                setBatchError('')
                            }}
                            placeholder="Select Batch"
                            containerClass="mt-2"
                            type="select"
                            errorText={batchError}
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput>
                    </div>

                </div>
                {/* <div className="row align-items-end "> */}
                <div className="text-right m-4">
                    {/* <div className=" col-12 m-2"> */}
                    <button className='btn btn-sm btn-primary' onClick={handleSearch} >Search</button>
                </div>
                {/* </div> */}
            </Card>
            <CollapseCard title="Mark List">
                {/* <div className="container-fluid table-container"> */}
                <div>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Register No</th>
                                <th>Department Reg.no</th>
                                <th>Internal Mark</th>
                                <th>External Mark</th>
                                <th>Int Converted Mark</th>
                                <th>Ext Converted Mark</th>
                                <th>Total Converted Mark</th>
                                <th>Adjusted Mark</th>
                                <th>Grade</th>
                                <th>University Grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {markReport?.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{row.name}</td>
                                        <td>{row.registerNo}</td>
                                        <td>{row.departmentRegNo}</td>
                                        <td>{row.intMark ? row.intMark : '-'}</td>
                                        <td>{row.extMark ? row.extMark : '-'}</td>
                                        <td>{row.intConvertedMark ? row.intConvertedMark : '-'}</td>
                                        <td>{row.extConvertedMark ? row.extConvertedMark : '-'}</td>
                                        <td>{row.totConvertedMark ? row.totConvertedMark : '-'}</td>
                                        <td>{row.adjustedMark ? row.adjustedMark : '-'}</td>
                                        <td>{row.gradeText ? row.gradeText : '-'}</td>
                                        <td>{row.unvGradeText ? row.unvGradeText : '-'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <div className="col-6">
                        {markReport.length > 0 && <div className="text-end mb-3">
                            <button
                                type="button"
                                onClick={handleDownloadPDF}
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> PDF
                            </button>
                            <CSVLink
                                data={csvData}
                                filename="Mark_report.csv"
                                className="m-1 btn btn-sm btn-primary"
                            >
                                <Feather.Download /> CSV
                            </CSVLink>
                        </div>
                        }
                    </div>
                </div>
            </CollapseCard>
        </PageContainer >
    </>
}
export default MarkReport